import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function RobuxCaculator() {
  const [calculatorMode, setCalculatorMode] = React.useState('BT');
  const [calculatorModeText, setCalculatorModeText] = React.useState('Robux Before Tax');
  const [robuxAmount, setRobuxAmount] = React.useState("");
  const onClickRobuxBT = () => {
    console.log('Robux Before Tax');
    setCalculatorMode('BT');
    setCalculatorModeText('Robux Before Tax');
  };
  const onClickRobuxAT = () => {
    console.log('Robux After Tax');
    setCalculatorMode('AT');
    setCalculatorModeText('Robux After Tax');
  };
  const calculateRobux = () => {
    if (calculatorMode === 'BT') {
        console.log('Calculating Robux Before Tax');
        return Math.floor(robuxAmount - (robuxAmount * 0.3));
    }
    if(calculatorMode === 'AT') {
        console.log('Calculating Robux After Tax');
        return Math.ceil(robuxAmount/0.7);
    }
  };
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Robux Tax Calculator
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            The Robux Tax Calculator is a simple tool that allows you to calculate the amount of Robux you will receive after tax. To use the calculator, simply select the mode you want to calculate (Robux Before Tax or Robux After Tax) and enter the amount of Robux you have. The calculator will then show you the amount of Robux you will receive after tax.
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignSelf="center">
            <Button onClick={onClickRobuxBT} sx={{margin: '10px'}} color="success" variant="contained">Robux Before Tax</Button>
            <Button onClick={onClickRobuxAT} sx={{margin: '10px'}} variant="contained">Robux After Tax</Button>
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <TextField
              onInput={(e) => {
                console.log(e.target.value);
                setRobuxAmount(e.target.value);
              }}
              value={robuxAmount}
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label={calculatorModeText}
              type='number'
              placeholder={calculatorModeText}
              inputProps={{
                autoComplete: 'off'
              }}
            />
          </Stack>
          {/* <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
        </Stack>
        <Box component="section" sx={{ p: 2, border: '1px dashed grey',margin: '10px' }}>
            <Typography variant="h6">
                {calculatorMode === "BT" ? "You would get:" : "You would need:"}
            </Typography>
            <Typography variant="h5">
                {calculateRobux()} Robux
            </Typography>
        </Box>
        {/* <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        /> */}
      </Container>
    </Box>
  );
}
