import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
const TOS = () => {
    return (
    <Container
        id="faq"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box sx={{ width: '100%' }}>
        <h1>Terms of Service</h1>
        <p><strong>Last Updated:</strong> [Date]</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using RobuxTax.com (the "Site"), you agree to comply with and be bound by the following terms and conditions (the "Terms"). If you do not agree to these Terms, please do not use the Site.</p>

        <h2>2. Changes to Terms</h2>
        <p>We reserve the right to update or modify these Terms at any time without prior notice. Your continued use of the Site after any changes indicates your acceptance of the new Terms. The date of the last update will be noted at the top of this page.</p>

        <h2>3. Use of the Site</h2>
        <p>You agree to use the Site only for lawful purposes and in accordance with these Terms. You agree not to:</p>
        <ul>
            <li>Violate any applicable laws or regulations</li>
            <li>Engage in any fraudulent or deceptive practices</li>
            <li>Infringe upon the intellectual property rights of others</li>
            <li>Transmit any harmful or malicious software or code</li>
            <li>Interfere with or disrupt the Site or servers</li>
        </ul>

        <h2>4. Intellectual Property</h2>
        <p>All content on the Site, including text, graphics, logos, and software, is the property of RobuxTax.com or its content suppliers and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content on the Site without our express written permission.</p>

        <h2>5. User Accounts</h2>
        <p>If you create an account on the Site, you are responsible for maintaining the confidentiality of your account information and password. You agree to accept responsibility for all activities that occur under your account. We reserve the right to terminate accounts, refuse service, or remove or edit content at our sole discretion.</p>

        <h2>6. Third-Party Links</h2>
        <p>The Site may contain links to third-party websites or services that are not owned or controlled by RobuxTax.com. We are not responsible for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that RobuxTax.com shall not be liable for any damages or losses arising from your use of any third-party websites or services.</p>

        <h2>7. Disclaimers</h2>
        <p>The Site and its content are provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the Site's operation or the information, content, or materials included on the Site. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.</p>

        <h2>8. Limitation of Liability</h2>
        <p>In no event shall RobuxTax.com or its affiliates, officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the Site, whether based on contract, tort, strict liability, or otherwise, even if advised of the possibility of such damages.</p>

        <h2>9. Indemnification</h2>
        <p>You agree to indemnify, defend, and hold harmless RobuxTax.com and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, and expenses, including reasonable attorneys' fees, arising from or related to your use of the Site or any violation of these Terms.</p>

        <h2>10. Governing Law</h2>
        <p>These Terms and your use of the Site shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. Any legal action or proceeding arising out of or related to these Terms or your use of the Site shall be brought exclusively in the courts located in [Your Jurisdiction].</p>

        <h2>11. Termination</h2>
        <p>We reserve the right to terminate or suspend your access to the Site at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Site, us, or third parties, or for any other reason.</p>

        <h2>12. Contact Information</h2>
        <p>If you have any questions or concerns about these Terms, please contact us at:</p>
        <p class="contact-info">
            <strong>Email:</strong> [Your Contact Email]<br/>
            <strong>Address:</strong> [Your Business Address]
        </p>
        </Box>
    </Container>
    );
};

export default TOS;