import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
const PrivacyPolicy = () => {
    return (
    <Container
        id="faq"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box sx={{ width: '100%' }}>
        <h1>Privacy Policy</h1>
        <p><strong>Last Updated:</strong> [Date]</p>

        <h2>1. Information We Collect</h2>

        <h3>1.1 Personal Information</h3>
        <p>We may collect personal information that you voluntarily provide to us when you use our Site. This may include:</p>
        <ul>
            <li>Name</li>
            <li>Email Address</li>
            <li>Account Information (such as usernames)</li>
            <li>Payment Information (if applicable)</li>
        </ul>

        <h3>1.2 Non-Personal Information</h3>
        <p>We may also collect non-personal information automatically when you interact with our Site. This includes:</p>
        <ul>
            <li>IP Address</li>
            <li>Browser Type</li>
            <li>Operating System</li>
            <li>Pages Visited and Time Spent on each page</li>
            <li>Referral URLs</li>
        </ul>

        <h3>1.3 Cookies and Similar Technologies</h3>
        <p>We use cookies and similar tracking technologies to enhance your experience on our Site. Cookies help us understand user behavior, improve our services, and provide tailored content.</p>

        <h2>2. How We Use Your Information</h2>

        <h3>2.1 Personal Information</h3>
        <p>We may use your personal information to:</p>
        <ul>
            <li>Provide and improve our services</li>
            <li>Process transactions and send related information</li>
            <li>Communicate with you, including responding to inquiries and providing customer support</li>
            <li>Send promotional materials, updates, and special offers (if you have opted in)</li>
            <li>Comply with legal obligations</li>
        </ul>

        <h3>2.2 Non-Personal Information</h3>
        <p>Non-personal information is used to:</p>
        <ul>
            <li>Analyze usage patterns and trends to improve our Site</li>
            <li>Enhance security and prevent fraud</li>
            <li>Tailor advertising and content to user preferences</li>
        </ul>

        <h2>3. How We Share Your Information</h2>

        <h3>3.1 Third-Party Service Providers</h3>
        <p>We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and email delivery. These third parties are obligated to protect your information and use it only for the services they provide.</p>

        <h3>3.2 Legal Requirements</h3>
        <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</p>

        <h3>3.3 Business Transfers</h3>
        <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</p>

        <h2>4. Security of Your Information</h2>
        <p>We take the security of your personal information seriously and implement reasonable administrative, technical, and physical safeguards to protect it. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

        <h2>5. Your Rights</h2>
        <p>You have the following rights regarding your personal information:</p>
        <ul>
            <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
            <li><strong>Correction:</strong> You can request that we correct any inaccuracies in your personal information.</li>
            <li><strong>Deletion:</strong> You can request that we delete your personal information, subject to certain exceptions.</li>
            <li><strong>Opt-Out:</strong> You can opt out of receiving marketing communications from us at any time by following the instructions in those communications.</li>
        </ul>

        <h2>6. Children's Privacy</h2>
        <p>Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.</p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, with the "Last Updated" date at the top. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
        <p class="contact-info">
            <strong>Email:</strong> [Your Contact Email]<br/>
            <strong>Address:</strong> [Your Business Address]
        </p>
        </Box>
    </Container>
    );
};

export default PrivacyPolicy;